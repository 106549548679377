@import url(https://fonts.googleapis.com/css?family=Quicksand);
@charset "UTF-8";
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background: -webkit-linear-gradient(left, #222, #111);
  background: linear-gradient(to right, #222, #111);
  font-family: Ubuntu, sans-serif;
  overflow-y: scroll; }

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-transition: all .5s;
  transition: all .5s; }

.spinner {
  width: 40px;
  height: 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%); }

.cube1, .cube2 {
  background-color: #333;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
  animation: sk-cubemove 1.8s infinite ease-in-out; }

.cube2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s; }

@-webkit-keyframes sk-cubemove {
  25% {
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5); }
  50% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg); }
  75% {
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5); }
  100% {
    -webkit-transform: rotate(-360deg); } }

@keyframes sk-cubemove {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5); }
  50% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg); }
  50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg); }
  75% {
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5); }
  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg); } }

@-webkit-keyframes glaucoma {
  from {
    opacity: 1;
    text-shadow: 0 0 75px black;
    color: transparent; }
  to {
    opacity: 1;
    text-shadow: 0;
    color: transparent; } }

@keyframes glaucoma {
  from {
    opacity: 1;
    text-shadow: 0 0 75px black;
    color: transparent; }
  to {
    opacity: 1;
    text-shadow: 0;
    color: transparent; } }

.txt-rotate {
  min-height: 70px; }

.txt-rotate > .wrap {
  border-right: 0.08em solid #222;
  -webkit-animation: blink-caret;
          animation: blink-caret;
  -webkit-animation-duration: 0s;
          animation-duration: 0s;
  -webkit-animation-iteration-count: infite;
          animation-iteration-count: infite; }

@-webkit-keyframes blink-caret {
  from, to {
    border-color: transparent; }
  50% {
    border-color: #222; } }

@keyframes blink-caret {
  from, to {
    border-color: transparent; }
  50% {
    border-color: #222; } }

.notransition {
  -webkit-transition: none !important;
  transition: none !important; }

.container {
  overflow: hidden; }

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background: #000;
  opacity: 0; }

.visible-vis {
  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity 1.5s linear;
  transition: opacity 1.5s linear; }

.hidden-vis {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 0s 1.5s, opacity 1.5s linear;
  transition: visibility 0s 1.5s, opacity 1.5s linear; }

.masonry-container {
  width: 100%;
  margin: auto;
  margin-top: 40px;
  border: 0px solid rgba(0, 0, 0, 0.1); }

.masonry {
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: stretch;
  width: 100%;
  margin: auto; }

.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: stretch;
  flex: 1 0; }

.tile-container {
  margin-bottom: -5px; }

.tile {
  margin: 2px;
  margin-bottom: 6px;
  position: relative;
  font-size: 26px;
  overflow: hidden;
  z-index: 1;
  -webkit-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
  border-radius: 2px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5); }
  .tile .picture {
    margin-bottom: -7px; }
    .tile .picture img {
      border-radius: 1px;
      width: 150%; }
  .tile .details {
    -webkit-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;
    position: absolute;
    font-family: 'Quicksand', sans-serif;
    font-weight: bold;
    font-size: 20px;
    right: 0;
    left: 0;
    bottom: 10%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    opacity: .9;
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)); }
    .tile .details p {
      margin: 0 auto !important;
      font-weight: bold;
      font-size: 22px;
      text-align: left !important;
      font-family: 'Quicksand', sans-serif;
      color: white; }
    .tile .details .title {
      clear: both; }
    .tile .details .tag {
      border: 0px;
      padding: 3px;
      background: rgba(204, 204, 204, 0.2);
      text-align: center;
      color: white;
      float: left;
      font-size: 12px;
      margin: 2px; }
      .tile .details .tag.green {
        background: rgba(38, 230, 30, 0.5); }
      .tile .details .tag.blue {
        background: rgba(46, 150, 224, 0.8); }
      .tile .details .tag.yellow {
        background: rgba(238, 234, 3, 0.5); }
  .tile:hover {
    opacity: 1;
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    box-shadow: 0px 0px 51px 0px rgba(0, 0, 0, 0.75);
    z-index: 1000;
    cursor: pointer; }

.blur {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px); }

.modal-about-me {
  top: 40% !important; }

.intro {
  height: 600px;
  font-family: 'Quicksand', sans-serif;
  display: flex;
  flex-direction: row;
  background: #FAF6F5;
  box-shadow: 0px 0px 51px 0px rgba(0, 0, 0, 0.75);
  overflow: hidden;
  align-items: center;
  justify-content: center; }
  .intro p {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    font-family: 'Quicksand', sans-serif;
    font-size: 40px;
    text-transform: none;
    color: white; }

.coder {
  font-family: Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New; }

.blue-color {
  color: rgba(46, 150, 224, 0.8); }

.row {
  display: flex;
  flex-direction: row; }

.item {
  display: flex;
  flex-direction: column; }

.intro p {
  color: black;
  font-weight: 100;
  font-size: 18px;
  text-align: left;
  margin-left: 5px; }

@-webkit-keyframes slideInFromBottom {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes slideInFromBottom {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.intro img {
  width: 575px;
  margin-top: 427px; }

.intro h1 {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 46px; }
  @media only screen and (max-device-width: 710px) {
    .intro h1 {
      margin: 0 5px; } }

.my-work {
  color: white;
  font-size: 18px;
  margin-top: 100px;
  text-align: center;
  text-transform: uppercase;
  height: 1px;
  margin-bottom: 30px;
  position: relative;
  width: 60%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

.my-work h3 {
  position: absolute;
  top: -43px;
  text-align: center;
  width: 300px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-weight: 100; }
  .my-work h3:before {
    content: '';
    background: -webkit-gradient(radial, 100% 100%, 0, 0 0, 350, from(#fff), to(#000));
    width: 300px;
    height: 1px;
    left: -244px;
    top: 16px;
    position: absolute; }
  .my-work h3:after {
    content: '';
    background: -webkit-gradient(radial, 0 0, 0, 50% 50%, 350, from(#fff), to(#000));
    width: 300px;
    height: 1px;
    left: 244px;
    top: 16px;
    position: absolute; }

.modal {
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
  -webkit-animation: fadeIn .3s ease-in;
          animation: fadeIn .3s ease-in;
  color: white;
  padding: 10px;
  z-index: 5000;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  background: rgba(0, 0, 0, 0);
  border-radius: 5px; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.modal-background {
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  -webkit-animation: fadeIn .3s ease-in-out;
          animation: fadeIn .3s ease-in-out;
  position: fixed;
  z-index: 3000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5); }

[class*='close-'] {
  color: white;
  font: 25px/100% arial, sans-serif;
  position: absolute;
  right: 5px;
  text-decoration: none;
  -webkit-transition: all .1s;
  transition: all .1s;
  text-shadow: 0 1px 0 black;
  top: 5px;
  z-index: 4000; }

[class*='close-']:hover {
  color: #777;
  text-shadow: 0 1px 0 black; }

.close-thick:after {
  content: '\2716';
  /* UTF-8 symbol */
  zoom: 150%; }

.image-container {
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 200px;
  right: 10px;
  overflow: hidden; }

.text-container {
  position: absolute;
  bottom: 10px;
  top: 10px;
  left: 10px;
  right: 10px;
  color: white; }
  .text-container h2 {
    margin-bottom: 20px; }
  .text-container p {
    margin: 0 auto;
    text-align: left;
    width: 100%;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    color: white; }

.small-text {
  font-size: 16px !important;
  list-style: none; }

span {
  display: block; }

.btn {
  text-transform: uppercase;
  box-sizing: border-box;
  display: flex;
  flex: 1 0;
  font-size: 14px;
  border: 0px;
  padding: 6px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  background: #222;
  color: #aaa;
  position: relative; }

.btn:after {
  content: '';
  height: 50%;
  width: 1px;
  position: absolute;
  right: 0;
  top: 25%;
  background-color: #444; }

.btn:last-child:after {
  content: '';
  width: 0px; }

.my-name {
  flex: 2 1;
  font-size: 50px !important;
  text-transform: uppercase !important; }

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: stretch;
  position: absolute;
  z-index: 20000;
  top: 0;
  right: 0;
  left: 0;
  line-height: 45px;
  height: 60px;
  box-shadow: 0px 0px 51px 0px rgba(0, 0, 0, 0.75); }
  .buttons p {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    font-family: 'Quicksand', sans-serif;
    font-size: 40px;
    text-transform: none;
    color: white; }

.btn:hover {
  cursor: pointer;
  -webkit-filter: brightness(120%);
          filter: brightness(120%);
  border-bottom: 4px solid #2e96e0 !important; }

.btn-link {
  margin-top: 20px;
  margin-right: 5px;
  border: none;
  padding: 12px 18px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  color: white;
  background: #2e96e0;
  cursor: pointer;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -webkit-transition: all .22s;
  transition: all .22s;
  border-radius: 3px;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.2), 0 3px 6px rgba(0, 0, 0, 0.2); }

.btn-link:hover {
  color: #fefefe;
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
  box-shadow: 0 7px 14px rgba(64, 64, 250, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  -webkit-filter: brightness(120%);
          filter: brightness(120%); }

.btn.blue {
  color: #2e96e0; }

.hidden {
  display: none; }

.about-me {
  width: 50%;
  margin-bottom: 20px;
  margin-right: 20px;
  float: left; }

.footer-container {
  position: absolute;
  /* bottom: 0; */
  margin-top: 20px;
  height: 40px;
  padding-top: 13px;
  opacity: .7;
  background: black;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden; }

.footer {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  color: white;
  height: 30px;
  font-size: 11px;
  text-align: center; }

@media only screen and (max-device-width: 710px) {
  .intro {
    padding: 0 20px;
    padding-top: 140px; }
  .row {
    justify-content: center;
    align-items: center; }
  .row.header-intro {
    flex-direction: column; }
  .intro img {
    display: none; }
  .djfull {
    display: none; }
  .djabv {
    display: inherit;
    align-items: center;
    justify-content: center;
    font-size: 45px !important; }
  .lifull {
    display: none; }
  .liabv {
    display: inherit;
    align-items: center;
    justify-content: center; }
  .sofull {
    display: none; }
  .soabv {
    display: inherit;
    align-items: center;
    justify-content: center; }
  .masonry {
    display: inherit; }
  [class*='close-'] {
    color: white;
    text-shadow: 0 1px 0 black; }
  [class*='close-']:hover {
    color: #777;
    text-shadow: 0 1px 0 black; } }

@media only screen and (max-width: 930px) {
  .intro {
    padding: 0 20px; }
  .intro img {
    display: none; }
  .djfull {
    display: none; }
  .djabv {
    display: inherit;
    align-items: center;
    justify-content: center; }
  .lifull {
    display: none; }
  .liabv {
    display: inherit;
    align-items: center;
    justify-content: center; }
  .sofull {
    display: none; }
  .soabv {
    display: inherit;
    align-items: center;
    justify-content: center; } }

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle; }

.btn2 {
  background-color: white;
  border: 1px solid #ccc;
  color: #555;
  padding: 0.5rem;
  text-transform: lowercase; }

.btn2--block {
  display: block;
  width: 100%; }

.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: space-between;
  margin: 0 7px;
  padding: 0; }

.cards p {
  font-family: 'Roboto','Helvetica Neue', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.cards__item {
  display: flex; }
  @media (min-width: 0px) {
    .cards__item {
      width: 100%;
      margin-bottom: 20px; } }
  @media (min-width: 700px) {
    .cards__item {
      width: 49%; } }
  @media (min-width: 900px) {
    .cards__item {
      width: 32.5%; } }
  @media (min-width: 1000px) {
    .cards__item {
      width: 19.25%; } }

.card {
  border-radius: 0.25rem;
  box-shadow: 0 15px 15px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  -webkit-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out; }
  .card:hover {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
    cursor: pointer; }
    .card:hover .card__image {
      -webkit-filter: contrast(100%);
              filter: contrast(100%); }
  .card .tag {
    border: 0px;
    font-family: Ubuntu, sans-serif;
    background: rgba(204, 204, 204, 0.2);
    text-align: center;
    color: white;
    float: left;
    font-size: 12px;
    margin: 2px;
    border-radius: 2px;
    padding: 3px 6px; }
    .card .tag.green {
      background: rgba(38, 230, 30, 0.5); }
    .card .tag.blue {
      background: rgba(46, 150, 224, 0.8); }
    .card .tag.yellow {
      background: rgba(238, 234, 3, 0.5); }

.card__content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1em 0; }

.card__image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  -webkit-filter: contrast(70%);
          filter: contrast(70%);
  overflow: hidden;
  position: relative;
  -webkit-transition: -webkit-filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
  transition: -webkit-filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
  transition: filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
  transition: filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91), -webkit-filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91); }
  .card__image::before {
    content: "";
    display: block;
    padding-top: 56.25%; }
  @media (min-width: 40rem) {
    .card__image::before {
      padding-top: 66.6%; } }

.card__image--flowers {
  background-image: url(https://unsplash.it/800/600?image=82); }

.card__image--river {
  background-image: url(https://unsplash.it/800/600?image=11); }

.card__image--record {
  background-image: url(https://unsplash.it/800/600?image=39); }

.card__image--fence {
  background-image: url(https://unsplash.it/800/600?image=59); }

.card__title {
  color: #ccc;
  font-size: 1.25rem;
  font-weight: 500;
  text-transform: uppercase;
  font-family: 'Quicksand', sans-serif; }
  @media (max-width: 999px) {
    .card__title {
      display: flex;
      justify-content: start;
      letter-spacing: 1px; } }

.card__text {
  flex: 1 1 auto;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;
  color: #ccc; }
  @media (max-width: 999px) {
    .card__text {
      display: flex;
      justify-content: start; } }

.text__content {
  opacity: 1;
  font-family: 'Quicksand', sans-serif !important;
  margin-top: -10px; }

